<template>
  <!-- <div> -->
  <!-- input -->
  <!-- <div class="d-flex align-items-center justify-content-center gap-10" v-if="false">
        <div>Entre</div>
      <b-form-group class="mr-2">
        <b-form-select
          v-model="ldot"
        >
          <b-form-select-option
            v-for="n in 100"
            :key="n"
            :value="n"
          >
            {{ n }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
      <div>Et</div>
      <b-form-group>
        <b-form-input
          v-model="rdot"
        />
      </b-form-group>
    </div> -->

  <!-- slider -->
  <vue-slider
    :min-range="10"
    :direction="direction"
    :max-range="1000"
    v-model="value"
  />
  <!-- </div> -->
</template>

<script>
import {
  BFormSelect,
  BFormSelectOption,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import store from "@/store/index";

export default {
  components: {
    VueSlider,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BFormSelectOption,
  },
  data() {
    return {
      ldot: 1,
      rdot: 50,
      dir: "ltr",
    };
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val, old) {
        const output = {
          min: this.ldot,
          max: this.rdot,
        };
        this.$emit("input", output);
      },
    },
  },
  computed: {
    value: {
      get() {
        return [this.ldot, this.rdot];
      },
      set([ldot, rdot]) {
        this.ldot = ldot;
        this.rdot = rdot;
      },
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl";
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr";
      return this.dir;
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/vue/libs/vue-slider.scss";
</style>