<template>
  <div>
    <div class="d-flex flex-wrap gap-10">
      <b-form-group
        v-if="canShowFilter('trancheAge')"
        label-cols-lg="12"
      >
        <template #label>
          <div>
            Tranche d'âge ( Entre {{ form.trancheAge.min }} an(s) et
            {{ form.trancheAge.max || 0 }} an(s))
          </div>
        </template>
        <template #default>
          <div class="min-h-42-px d-grid align-items-center">
            <SliderWithInput v-model="form.trancheAge" />
          </div>
        </template>
      </b-form-group>
      <b-form-group
        v-if="canShowFilter('periode')"
        label-cols-xl="12"
        label="Période"
      >
        <b-input-group>
          <DateRangeWidget
            v-model="form.periode"
            :range="form.periode"
          />
        </b-input-group>
      </b-form-group>
      <b-form-group
        v-if="canShowFilter('nationalite')"
        label-cols-lg="12"
        label="Nationalité"
      >
        <!-- <v-select
          v-model="form.nationalite"
          type="text"
          :options="listPays"
          label="name"
        /> -->
        <SelectCountriesList
          v-model="form.nationalite"
          placeholder="Nationalité"
        />
      </b-form-group>
      <b-form-group
        v-if="canShowFilter('peysProvenance')"
        label-cols-lg="12"
        label="Pays provenance"
      >
        <!-- <v-select
          v-model="form.paysProvenance"
          type="text"
          :options="listPays"
          label="name"
        /> -->
        <SelectCountriesList
          v-model="form.paysProvenance"
          placeholder="Pays provenance"
        />
      </b-form-group>
      <b-form-group
        v-if="canShowFilter('sexe')"
        label-cols-lg="12"
      >
        <template #label>
          <div>Sexe</div>
        </template>
        <template #default>
          <div class="min-h-42-px d-grid align-items-center">
            <b-form-checkbox-group
              v-model="form.sexe"
              :options="['Masculin', 'Feminin']"
              class="demo-inline-spacing"
              name="radio-sexe"
            />
          </div>
        </template>
      </b-form-group>
      <b-form-group
        v-if="canShowFilter('motifVoyage')"
        label-cols-lg="12"
      >
        <template #label>
          <div>Motifs du voyage</div>
        </template>
        <b-form-checkbox-group
          v-model="form.motifVoyage"
          :options="motif_travel"
          value-field="id"
          text-field="name"
          name="some-radio9"
        />
      </b-form-group>
      <b-form-group
        v-if="canShowFilter('modeHebergement')"
        label-cols-lg="12 "
      >
        <template #label>
          <div>Mode hébergement</div>
        </template>
        <b-form-checkbox-group
          v-model="form.modesHebergement"
          :options="modesHebergement"

          name="some-radio9"
        />
      </b-form-group>
      <!-- Mode principal de transport à l'intérieur du Bénin -->
      <b-form-group
        v-if="canShowFilter('modeTransportInterieurBenin')"
        label-cols-lg="12"
        label="Mode principal de transport à l'intérieur du Bénin :"
      >

        <b-form-checkbox-group
          v-model="form.modeTransportInterieurBenin"
          :options="modePrincipalTransportAuBenin"
          class="demo-inline-spacing mb-1 ml-1"
          value-field="value"
          text-field="text"
          disabled-field="disabled"
        />

      </b-form-group>
      <!-- Catégorie socio professionnelle -->
      <b-form-group
        v-if="canShowFilter('categorieSocioProfessionnelle')"
        label-cols-lg="12"
        label="Catégorie socio-professionnelle :"
      >

        <b-form-checkbox-group
          v-model="form.categorieSocioProfessionnelle"
          :options="categorieSocioProList"
          value-field="value"
          text-field="text"
          disabled-field="disabled"
        />

      </b-form-group>
      <!-- Lieu de résidence habituelle -->
      <b-form-group
        v-if="canShowFilter('lieuResidence')"
        label-cols-lg="12"
        label="Lieu de résidence habituelle :"
        class="w-100"
      >
        <template>
          <div class="pl-2">

            <!-- Lieu de résidence habituelle (adresse) -->
            <b-form-group
              label-cols-md="2"
              label="Adresse"
            >

              <b-form-input
                v-model="form.lieuResidence.adresse"
                placeholder="L'adresse du lieu de résidence"
              />

            </b-form-group>
            <b-row>
              <!-- Lieu de résidence habituelle (code postal) -->
              <b-col class="md-6">
                <b-form-group
                  label-cols-md="4"
                  label="Code Postal"
                >
                  <b-form-input
                    v-model="form.lieuResidence.bp"
                    placeholder="Le code postal du lieu de résidence"
                  />
                </b-form-group>
              </b-col>

              <!-- Lieu de résidence habituelle (Ville/Commune) -->
              <b-col class="md-6">
                <b-form-group
                  label-cols-md="3"
                  label="Ville/Commune :"
                >

                  <b-form-input
                    v-model="
                      form.lieuResidence.villeCommune
                    "
                    placeholder=""
                  />
                  <!-- <v-select
                    v-model="form.lieuResidence.villeCommune"
                    :reduce="(option) => option.name"
                    type="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="ville_commune"
                    label="name"
                    placeholder=" Ville ou Commune du lieu de résidence"
                  /> -->

                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- bof: {{form.lieuResidence.departement}} -->
              <!-- Lieu de résidence habituelle (Département) -->
              <b-col class="md-6">
                <b-form-group
                  label-cols-md="4"
                  label="Département"
                >
                  <b-form-input
                    v-model="form.lieuResidence.departement"

                    placeholder="Département du lieu de résidence"
                  />
                  <!-- <v-select
                  v-model="form.lieuResidence.departement"
                  type="text"
                  :options="departement"
                  placeholder="Département du lieu de résidence"
                /> -->
                </b-form-group>
              </b-col>

              <!-- Lieu de résidence habituelle (Pays) -->
              <b-col class="md-6">
                <b-form-group
                  label-cols-md="3"
                  label="Pays :"
                >

                  <SelectCountriesList
                    v-model="form.lieuResidence.pays"
                    placeholder="Pays du lieu de résidence"
                  />
                  <!-- <v-select
                    v-model="form.lieuResidence.pays"
                    type="text"
                    :options="pays"
                    label="name"
                    placeholder="Pays du lieu de résidence"
                  /> -->

                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </template>
      </b-form-group>

    </div>

    <div v-if="canShowFilter('localisation')">
      <b-form-group
        label-cols-xl="12"
        label="Localisation"
      >
        <strong>Departement :</strong> {{ form.departement || "Non renseigné" }}
        <br>
        <strong>Commune :</strong> {{ form.commune || "Non renseigné" }} <br>
        <strong>Arrondissement :</strong>
        {{ form.arrondissement || "Non renseigné" }} <br>
        <strong>Quartier/village :</strong> {{ form.qtier || "Non renseigné" }}
        <br>
        <b-button
          variant="outline-primary"
          @click="showDepartementFormModal"
        >
          Choisir une localisation
        </b-button>
      </b-form-group>
      <b-modal
        :ref="departementFormModalRef"
        content-class="shadow"
        title="Choisir une localisation"
        centered
        ok-only
        modal-class="modal-primary"
        ok-title="OK"
      >
        <DepartementCommuneArrondissementVillageForm
          :provided-labels="departementFormProps.departementFormLabels"
          :can-update-child-on-parent-changes="
            departementFormProps.canUpdateChildOnParentChanges
          "
          :initial-values="getDepartementCommunesArrAndVillage"
          @input="onDepartementFormChange"
        />
        Departement : {{ form.departement }} <br>
        Commune : {{ form.commune }} <br>
        Arrondissement : {{ form.arrondissement }} <br>
        Quartier/village : {{ form.qtier }} <br>
      </b-modal>
    </div>

    <b-button
      class="float-right"
      variant="primary"
      @click="submitForm"
    >

      <b-spinner
        v-if="submitted && isLoading"
        small
      />
      <span v-if="!(submitted && isLoading)">Filtrer</span>
    </b-button>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormFile,
  BSpinner,
  BFormSelect,
  BFormSelectOption,
  BModal,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapActions, mapState, mapMutations } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
import passwordValidators from '@/helpers/password-validators'
import DateRangeWidget from '@/components/DateRangeWidget.vue'
import SliderWithInput from '@/components/SliderWithInput.vue'
import DepartementCommuneArrondissementVillageForm from '@/components/DepartementCommuneArrondissementVillageForm.vue'
import SelectCountriesList from '@/components/SelectCountriesList.vue'

// store modules and vuex utilities
import paramsStoreModule from '@/store/params'
import statisticsStoreModule from '@/store/statistics'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

const filters = {
  periode: {},
  departement: '',
  commune: '',
  arrondissement: '',
  qtier: '',
  trancheAge: {
    min: 0,
    max: 0,
  },
  motifVoyage: [],
  sexe: [],
  peysProvenance: null,
  nationalite: null,
  modeHebergement: [],
  lieuResidence: {
    adresse: '',
    bp: '',
    villeCommune: '',
    departement: '',
    pays: '',
  },

  categorieSocioProfessionnelle: [],
  modeTransportInterieurBenin: [],
}

// 'lieuResidenceHabituelle',
// 'categorieSocioProfessionnelle',
// 'modeTransportInterieurDuPays',
export default {
  components: {
    SliderWithInput,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormFile,
    BModal,
    // validations
    AppLogo,
    vSelect,
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
    DateRangeWidget,
    DepartementCommuneArrondissementVillageForm,
    SelectCountriesList,
  },
  mixins: [togglePasswordVisibility],
  props: {
    // submit form validations, only if some field need to be controled
    triggerFormValidations: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    activeFilters: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: 'params', module: paramsStoreModule },
      { path: 'statistics', module: statisticsStoreModule },

    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules, passwordValidators, $v: useVuelidate({ $lazy: true }) }
  },
  data() {
    return {
      submitted: false,
      modePrincipalTransportAuBenin: [
        { text: 'Aérien', value: 'Aérien' },
        { text: 'Terrestre', value: 'Terrestre' },
        { text: 'Par eau', value: 'Par eau' },
      ],
      modesHebergement: [
        { text: 'Hôtel et assimilés ', value: 'Hôtel et assimilés ' },
        { text: 'Famille/Amis ', value: 'Famille/Amis' },
        { text: 'Autres', value: 'Autres' },
      ],
      motif_travel: [
        {
          id: 'Affaires et motif professionnels',
          name: 'Affaires et motif professionnels',
        },
        {
          id: 'Vacances, loisirs et détente',
          name: 'Vacances, loisirs et détente',
        },
        {
          id: 'Visite à des parents ou des amis',
          name: 'Visite à des parents ou des amis',
        },
        { id: 'Éducation et formation', name: 'Éducation et formation' },
        { id: 'Santé et soins médicaux', name: 'Santé et soins médicaux' },
        { id: 'Transit', name: 'Transit' },
        { id: 'Religion ou pèlerinage', name: 'Religion ou pèlerinage' },
        { id: 'Achats', name: 'Achats' },
        { id: 'Autres (A preciser)', name: 'Autres motif (A preciser)' },
      ],
      categorieSocioProList: [
        { text: 'Libéral/indépendant', value: 'Libéral/indépendant' },
        { text: 'Employé/salarié', value: 'Employé/salarié' },
        { text: 'Retraité', value: 'Retraité' },
        { text: 'Autres (à préciser)', value: 'Autres' },
      ],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      form: {
        ...filters,
      },
      departementFormProps: {
        canUpdateChildOnParentChanges: {
          departement: false,
          commune: false,
          arrondissement: false,
          qtier: false,
        },
        departementFormLabels: {
          departement: 'Département',
          commune: 'Communes',
          arrondissement: 'Arrondissements',
          qtier: 'Quartier/village',
        },
      },

      // isLoading: false,
      departementFormModalRef: 'departementFormModalRef',
    }
  },
  computed: {
    getDepartementCommunesArrAndVillage() {
      return {
        departement: this.form.departement,
        commune: this.form.commune,
        arrondissement: this.form.arrondissement,
        qtier: this.form.qtier,
      }
    },
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler(val, old) {
        // console.log('form', val)
        const output = this.activeFilters.map(key => ({ [key]: val[key] })).reduce((acc, curr) => ({ ...acc, ...curr }), {})
        /*
        // WIP: we need to update this later. This was added in order to integrate the stats filters datas with vuex
        // Do not remove
        */
        this.action_updateStatisticsFiltersValues({ context: '', data: output })
        /*
        // emit filters values whenever they change
        // Will emit only active flters
        */

        this.$emit('input', output)
      },
    },
    triggerFormValidations: {
      handler(val, old) {
        if (val === true) this.submitForm()
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  validations() {
    return {
      form: {
        departement: { required },
        commune: { required },
        arrondissement: { required },
        qtier: { required },
      },
    }
  },
  mounted() {},
  methods: {
    ...mapActions('params', {
      action_loadDepartments: 'loadDepartments',
      action_loadCommunes: 'loadCommunes',
      action_loadArrondissements: 'loadArrondissements',
      action_loadNeighborhoods: 'loadNeighborhoods',
    }),
    ...mapActions('statistics', {
      action_updateStatisticsFiltersValues: 'updateStatisticsFiltersValues',
    }),
    ...mapMutations('statistics', {
      submitStatisticsFiltersForm: 'setter_hasSubmitFilterForm',
    }),
    canShowFilter(key) {
      if (this.activeFilters.length === 0) return true
      return this.activeFilters.some(e => e === key)
    },
    submitForm() {
      this.submitted = true
      this.submitStatisticsFiltersForm(this.submitted)

      this.$emit('submit', {})

      //   const isFormCorrect = await this.$v.$validate();
    },
    onDepartementFormChange(val) {
      this.form = { ...this.form, ...val }
    },
    showDepartementFormModal() {
      this.$refs[this.departementFormModalRef].show()
    },
    hideDepartementFormModal() {
      this.$refs[this.departementFormModalRef].hide()
    },
  },
  computed: {
    ...mapState('params', {
      listPays: 'listPays',
    }),
  },
}
</script>

<style lang="scss">
</style>
