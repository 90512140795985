<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        {{ $attrs.title }}
      </b-card-title>
      <!-- <b-dropdown
        text="Choisir une periode"
        variant="transparent"
        class="chart-dropdown"
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in chartData.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <div v-if="false" class="row">
        <div class="col-lg-6">
          <h5>Par département</h5>
          <vue-apex-charts
            v-if="!isLoadingDepartements"
            type="pie"
            height="325"
            class="mt-2 mb-1"
            :options="departementsPie.chartOptions"
            :series="departementsPie.series"
          />
        </div>
        <div class="col-lg-6">
          <h5>Par communes</h5>
          <vue-apex-charts
            v-if="!isLoadingCommunes"
            type="pie"
            height="325"
            class="mt-2 mb-1"
            :options="communesPie.chartOptions"
            :series="communesPie.series"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <!-- <h5>
            Liste du nombre d'établissements</h5> -->

          <div class="mb-2">
            <div class="mb-1">Filtrer par</div>
            <div class="row">
              <div class="col">
                <b-form-select
                  v-model="selectedTypeLocalisation"
                  :options="typesLocalisation"
                  html-field="name"
                  value-field="key"
                />
              </div>
              <div class="col">
                <!-- <b-form-input
                  v-model="localSearchQuery"
                  :placeholder="filterInputPlaceholder"
                  @keyup="localFilter"
                /> -->
                <v-select
                  v-model="selectedLocations"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :placeholder="filterInputPlaceholder"
                  label="name"
                  :options="getLocalisationSelectOptions"
                  :reduce="(option) => option.name || option || ''"
                />
              </div>
            </div>
          </div>

          <div v-append-export-button />
          <b-table
            responsive
            :items="getTableData"
            :fields="getTableColums"
            :busy="isLoadingTableData"
            hover
          >
            <template v-if="hasThead" #thead-top="data">
              <b-tr>
                <b-th />
                <b-th colspan="8" class="text-center">
                  {{ theadLabel }}
                </b-th>
              </b-tr>
            </template>
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
          </b-table>

          <PaginationComponent
            :table-data="getTableData"
            :per-page="paginationData.perPage"
            :total-rows="paginationData.total"
            @change="onPaginationChange"
          />
        </div>
      </div>

      <!-- list group -->
      <div v-if="false" class="pt-25">
        <div
          v-for="(data, index) in chartData.listData"
          :key="index"
          class="d-flex justify-content-between"
          :class="
            index === Object.keys(chartData.listData).length - 1 ? '' : 'mb-1'
          "
        >
          <div class="series-info">
            <feather-icon
              :icon="data.icon"
              size="16"
              class="mr-50"
              :class="data.iconColor"
            />
            <span class="font-weight-bolder">{{ data.text }}</span>
          </div>
          <span>{{ data.result }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BTable,
  BFormSelect,
  BFormInput,
  BSpinner,
  BTh,
  BTr,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { mapActions, mapState } from "vuex";
import PaginationComponent from "@/components/PaginationComponent.vue";

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import statisticsStoreModule from "@/store/statistics";

import vSelect from "vue-select";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

import utilsService from "@/services/utils/utils.service";

const { departements, communes, arrondissements, quartiers } =
  utilsService.countriesAndPlaces({ stringOnly: false });

export default {
  components: {
    PaginationComponent,
    BSpinner,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
    BTable,
    BFormSelect,
    BFormInput,
    vSelect,
    BTh,
    BTr,
  },
  props: {
    tableDatas: {
      type: Object,
      default: () => ({
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      }),
    },
    tableColumns: {
      type: Array,
      default: () => [
        { key: "name", label: "Nom", sortable: true },
        { key: "nbrEts", label: "Nombre d'établissements", sortable: true },
      ],
    },

    hasThead: {
      type: Boolean,
      default: false,
    },
    theadLabel: {
      type: String,
      default: "",
    },
    isLoadingTableData: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "statistics", module: statisticsStoreModule },
    ];

    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  data() {
    return {
      selectedTypeLocalisation: "departements",
      selectedTypeEtablissement: "",
      selectedLocations: [],
      // isLoadingTableData: false,
      departements,
      communes,
      arrondissements,
      quartiers,
      localSearchQuery: "",
      // tableDatas: {
      //   departements: [],
      //   communes: [],
      //   arrondissements: [],
      //   villages: [],
      // },
      filteredTableDatas: {
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      },
      typesLocalisation: [
        { name: "Départements", key: "departements" },
        { name: "Communes", key: "communes" },
        { name: "Arrondissements", key: "arrondissements" },
        // { name: 'Quartiers', key: 'quartiers' },
      ],
      chartData: {},
      typesEtablissementsOptions: [],
      paginationData: {
        perPage: 10,
        total: 0,
      },
    };
  },
  computed: {
    filterInputPlaceholder() {
      switch (this.selectedTypeLocalisation) {
        case "departements":
          return "Rechercher un  ou plusieurs départements";
          break;

        case "communes":
          return "Rechercher une ou plusieurs communes";
          break;
        case "arrondissements":
          return "Rechercher un  ou plusieurs arrondissements";
          break;
        case "quartiers":
          return "Rechercher un  ou plusieurs quartiers";
          break;

        default:
          return "Rechercher par départements, communes, arrondissements, quartiers";
          break;
      }
    },
    getLocalisationSelectOptions() {
      switch (this.selectedTypeLocalisation) {
        case "departements":
          return this.departements || [];

        case "communes":
          return [
            ...new Set((this.communes || []).map((e) => e.name || e) || []),
          ].map((e) => ({ name: e }));

        case "arrondissements":
          return [
            ...new Set(
              (this.arrondissements || []).map((e) => e.name || e) || []
            ),
          ].map((e) => ({ name: e }));

        case "quartiers":
          return [];

        default:
          return [];
      }
    },
    getTableData() {
      const items = [...this.filteredTableDatas[this.selectedTypeLocalisation]];
      this.paginationData.total = items.length || 0;
      const paginateArray = (array, perPage, page) =>
        array.slice((page - 1) * perPage, page * perPage);
      const { perPage, currentPage } = this.paginationData;
      // console.log(items, this.paginationData)
      return paginateArray(items, perPage, currentPage || 1);
      // return this.filteredTableDatas[this.selectedTypeLocalisation];
    },
    getTableColums() {
      return this.tableColumns;
    },
  },
  watch: {
    localSearchQuery: {
      handler(val, old) {},
    },
    selectedLocations: {
      deep: true,
      handler(val, old) {
        // console.log('val :>> ', val)
        this.localFilter();
      },
    },
    selectedTypeLocalisation: {
      handler(val, old) {
        this.selectedLocations = [];
        this.filteredTableDatas[this.selectedTypeLocalisation] =
          this.tableDatas[this.selectedTypeLocalisation];
      },
    },
    tableDatas: {
      deep: true,
      immediate: true,
      handler(val, old) {
        this.filteredTableDatas[this.selectedTypeLocalisation] =
          val[this.selectedTypeLocalisation];
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  methods: {
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    ...mapActions("statistics", {
      action_getStatisticsAgencesVoyageToursOperators:
        "getStatisticsAgencesVoyageToursOperators",
      action_getStatisticsTransport: "getStatisticsTransport",
    }),
    ...mapActions("etablissements", {
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    onPaginationChange(val) {
      // console.log('paginationChange', val)
      this.paginationData = { ...this.paginationData, ...val };
    },
    localFilter() {
      // const query = this.localSearchQuery.toLowerCase()
      const queries = (this.selectedLocations || []).map((e) =>
        e.toLowerCase()
      );
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
        this.selectedTypeLocalisation
      ].filter((e) => {
        if (queries.length === 0) return true;
        return queries.includes(e.name.toLowerCase());
      });
      this.filteredTableDatas[this.selectedTypeLocalisation] =
        this.filteredTableDatas[this.selectedTypeLocalisation].filter(
          (e) => true
        );
    },
    getRandomInt(min = 10, max = 1000) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
  },
};
</script>
