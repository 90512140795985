<template>
  <StatsLocalisationsTableComponent
    :table-datas="tableDatas"
    :is-loading-table-data="isLoadingTableData"
    title="Nombre d'établissements par départements, communes, et arrondissements"
    :table-columns="tableColumns"
  />
</template>

<script>

import { mapActions, mapState } from 'vuex'
import StatsLocalisationsTableComponent from '@/components/StatsLocalisationsTableComponent.vue'

// store modules and vuex utilities
import etablissementsStroreModule from '@/store/etablissements'
import paramsStoreModule from '@/store/params'
import statisticsStoreModule from '@/store/statistics'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

import utilsService from '@/services/utils/utils.service'

const {
  departements, communes, arrondissements, quartiers,
} = utilsService.countriesAndPlaces({ stringOnly: false })

export default {
  components: {
    StatsLocalisationsTableComponent,
  },
  props: {
    tableDatas: {
      type: Object,
      default: () => ({
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      }),
    },

    isLoadingTableData: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'params', module: paramsStoreModule },
      { path: 'statistics', module: statisticsStoreModule },
    ]

    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  data() {
    return {
      selectedTypeLocalisation: 'departements',
      selectedTypeEtablissement: '',
      // isLoadingTableData: false,
      departements,
      communes,
      arrondissements,
      quartiers,
      localSearchQuery: '',
      // tableDatas: {
      //   departements: [],
      //   communes: [],
      //   arrondissements: [],
      //   villages: [],
      // },
      filteredTableDatas: {
        departements: [],
        communes: [],
        arrondissements: [],
        villages: [],
      },
      typesLocalisation: [
        { name: 'Départements', key: 'departements' },
        { name: 'Communes', key: 'communes' },
        { name: 'Arrondissements', key: 'arrondissements' },
        // { name: 'Quartiers', key: 'quartiers' },
      ],
      chartData: {},
      tableColumns: [
        { key: 'name', label: 'Nom', sortable: true },
        { key: 'nbrEts', label: "Nombre d'établissements", sortable: true },
      ],
      typesEtablissementsOptions: [],
      paginationData: {
        perPage: 10,
        total: 0,
      },
    }
  },
  computed: {
    filterInputPlaceholder() {
      switch (this.selectedTypeLocalisation) {
        case 'departements':
          return 'Rechercher un département'
          break

        case 'communes':
          return 'Rechercher une commune'
          break
        case 'arrondissements':
          return 'Rechercher un arrondissement'
          break
        case 'quartiers':
          return 'Rechercher un quartier'
          break

        default:
          return 'Rechercher par départements, communes, arrondissements, quartiers'
          break
      }
    },
    getTableData() {
      const items = [...this.filteredTableDatas[this.selectedTypeLocalisation]]
      this.paginationData.total = items.length || 0
      const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)
      const { perPage, currentPage } = this.paginationData
      // console.log(items, this.paginationData)
      return paginateArray(items, perPage, currentPage || 1)
      // return this.filteredTableDatas[this.selectedTypeLocalisation];
    },
    getTableColumsTitle() {
      return [
        {
          key: 'departement',
          label: 'Deoartement',
          sortable: true,
        },
        {
          key: '',
          label: "Nombre d'établissement",
          sortable: true,
        },
      ]
    },
  },
  watch: {
    localSearchQuery: {
      handler(val, old) {},
    },
    selectedTypeLocalisation: {
      handler(val, old) {
        this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[this.selectedTypeLocalisation]
      },
    },
    tableDatas: {
      deep: true,
      immediate: true,
      handler(val, old) {
        this.filteredTableDatas[this.selectedTypeLocalisation] = val[this.selectedTypeLocalisation]
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  methods: {
    ...mapActions('params', {
      action_loadDepartments: 'loadDepartments',
      action_loadCommunes: 'loadCommunes',
      action_loadArrondissements: 'loadArrondissements',
      action_loadNeighborhoods: 'loadNeighborhoods',
    }),
    ...mapActions('statistics', {
      action_getStatisticsAgencesVoyageToursOperators:
        'getStatisticsAgencesVoyageToursOperators',
      action_getStatisticsTransport: 'getStatisticsTransport',
    }),
    ...mapActions('etablissements', {
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    onPaginationChange(val) {
      // console.log('paginationChange', val)
      this.paginationData = { ...this.paginationData, ...val }
    },
    localFilter() {
      const query = this.localSearchQuery.toLowerCase()
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
        this.selectedTypeLocalisation
      ].filter(e => {
        if (!query) return true
        return e.name.toLowerCase().includes(query)
      })
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.filteredTableDatas[this.selectedTypeLocalisation].filter(
        e => true,
      )
    },
    getRandomInt(min = 10, max = 1000) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    },

  },

}
</script>
